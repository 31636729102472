.repair-step {
  display: grid;
  grid-gap: space(2);
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  padding-bottom: space(10);
  padding-top: space(10);
  width: 100%;
}

.repair-step__position {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  grid-column: 1;
  grid-row: 1;
  line-height: var(--font-size-16);
}

.repair-step__reparation-type {
  grid-column: 1 / span 2;
  grid-row: 2;

  & > div:last-of-type {
    display: flex;
    gap: space(3);
    flex-wrap: wrap;
  }
}

.repair-step__parts-order {
  grid-column: 1;
  grid-row: 3;

  & > div:last-of-type {
    & > button + button {
      margin-left: space(3);
    }
  }
}

.repair-step__amount {
  grid-column: 2;
  grid-row: 3;
  justify-self: end;
}

.repair-step__date {
  grid-column: 1 / span 2;
  grid-row: 4;
}

.repair-step__buttons {
  grid-column: 1 / span 2;
  grid-row: 5;
  justify-self: center;
  margin-top: space(4);

  & > button + button {
    margin-left: space(3);
  }
}

.repair-step__title {
  color: var(--color-white);
  font-size: var(--font-size-27);
  line-height: var(--font-size-32);
  margin-bottom: space(4);
  margin-top: space(3);
}

.repair-step__date-show {
  color: var(--color-gray);
  font-size: var(--font-size-19);
  line-height: var(--font-size-21);
  margin-bottom: space(3);
}

.repair-step__amount-input {
  position: relative;

  &::after {
    content: '€ TTC';
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-14);
    line-height: var(--font-size-16);
    position: absolute;
    right: 10px;
    top: 24px;
  }

  input {
    font-size: var(--font-size-18);
    height: 61px;
    padding-right: space(8);
  }
}
