.button {
  border-radius: var(--border-radius-22);
  font-size: var(--font-size-18);
  min-width: 155px;
  height: fit-content;
  padding: space(1.5) space(4);
  width: fit-content;

  // & + & {
  //   margin-left: space(3);
  // }

  &:disabled {
    opacity: 0.6;
  }
}

.button-primary {
  background-color: var(--color-cyan-blue-2);
  border: 2px solid var(--color-cyan-blue-2);
  color: var(--color-white);

  // &:hover,
  // &:focus {
  //   background-color: var(--color-cyan-blue-2--o70);
  //   border-color: var(--color-cyan-blue-2--o70);
  // }

  &--not-selected {
    background-color: var(--color-cyan-blue-2--o20);
    border-color: var(--color-cyan-blue-2--o20);
  }
}

.button-secondary {
  background-color: transparent;
  border: 2px solid var(--color-cyan-blue-2);
  color: var(--color-cyan-blue-2);

  &:hover,
  &:focus {
    background-color: var(--color-cyan-blue-2--o20);
  }
}

.button--navigation-table-page {
  color: var(--color-white);
  min-width: 0;
  padding: space(1);
}
