body {
  background-image: url('/assets/images/background.png');
  font-family: $font-primary;
  height: 100%;
  padding: var(--app-padding-x);
}

div#root {
  height: 100%;
}
