.table {
  align-self: start;
  color: var(--color-white);
  margin-bottom: space(6);
  margin-top: space(6);
  width: 100%;

  table {
    border-collapse: collapse;
  }
  table,
  th,
  td {
    border: 1px solid gray;
  }
}

.table__content {
  width: 100%;

  thead {
    background-color: var(--color-blue);

    tr > th > div {
      margin: space(2) auto;
    }

    button {
      color: var(--color-white);
    }
  }

  tbody > tr {
    &:nth-child(even) {
      background-color: #8d8d8d69;
    }

    td {
      padding: space(2);

      &:nth-of-type(4) {
        div {
          display: flex;
          justify-content: flex-end;
        }
      }

      &:last-of-type > div {
        display: flex;
        gap: space(2);

        button {
          min-width: 0;
        }
      }
    }
  }
}

.table__navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: space(4);
}
