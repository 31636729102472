.checkup-step {
  display: grid;
  grid-gap: space(2);
  grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
  grid-template-rows: repeat(2, auto) 1fr auto;
  height: 100%;
  padding-bottom: space(10);
  padding-top: space(10);
  width: 100%;
}

.checkup-step__position {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  grid-column: 1;
  grid-row: 1;
  line-height: var(--font-size-16);
}

.checkup-step__subtitle {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  grid-column: 5;
  grid-row: 1;
  justify-self: end;
  line-height: var(--font-size-16);
}

.checkup-step__title {
  color: var(--color-white);
  font-size: var(--font-size-27);
  grid-column: 1 / span 2;
  grid-row: 2;
  line-height: var(--font-size-32);
}

.checkup-step__vehicle-type {
  align-items: center;
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-27);
  grid-column: 4 / span 2;
  grid-row: 2;
  justify-self: end;
  line-height: var(--font-size-32);

  & > button {
    display: contents;
  }

  // & > span {
  // margin: 0 space(2);
  // }
}

.checkup-step__vehicle-view-previous {
  align-self: center;
  grid-column: 1;
  grid-row: 3;
  justify-self: center;
}

.checkup-step__vehicle-view {
  align-self: center;
  grid-column: 2 / span 3;
  grid-row: 3;
  justify-self: center;

  .marker-point {
    height: 36px !important;
    width: 36px !important;
    max-width: none;
  }
}

.checkup-step__vehicle-view-next {
  align-self: center;
  grid-column: 5;
  grid-row: 3;
  justify-self: center;
}

.checkup-step__buttons {
  grid-column: 2 / span 3;
  grid-row: 4;
  justify-self: center;

  & > button + button {
    margin-left: space(3);
  }
}

.checkup-step__trash {
  align-self: center;
  grid-column: 5;
  grid-row: 4;
  justify-self: end;

  img {
    height: 26px;
    width: 26px;
  }
}
