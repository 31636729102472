$border-radius: 6, 22;
$font-sizes: 12, 14, 16, 18, 19, 21, 27, 32;

:root {
  --app-height: 100vh;

  --space: 6px; // Base multiplier
  --container-base-width: 1140px; // Container base width
  --app-padding-x: calc(var(--space) * 6);

  --container-padding: calc(var(--space) * 3); // Container padding
  --container-width: calc(
    var(--container-base-width) - var(--container-padding) * 2
  ); // Container width without padding

  --color-blue: #40abca;
  --color-cyan-blue: #78d7e8;
  --color-cyan-blue-2: #13b3db;
  --color-cyan-blue-2--o70: #13b3dbb3;
  --color-cyan-blue-2--o20: #13b3db33;
  --color-gray: #999dc7;
  --color-gray-blue: #698c97;
  --color-green-dark: #148581;
  --color-green-light: #16e7cf;
  --color-white: #ffffff;

  @each $br in $border-radius {
    --border-radius-#{$br}: calc(#{$br} * 1px);
  }

  @each $font-size in $font-sizes {
    --font-size-#{$font-size}: calc(#{$font-size}/ 16 * 1rem);
  }

  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;

  --ease-standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
  --ease-deceleration-curve: cubic-bezier(0, 0, 0.2, 1);
  --ease-acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
  --ease-sharp-curve: cubic-bezier(0.4, 0, 0.6, 1);
}

@media only screen and (max-width: 1140px) {
  :root {
    --container-width: calc(100vw - var(--container-padding) * 2);
  }
}
