// Container with padding
// ==================
%container,
.container {
  box-sizing: border-box;
  height: 100%;
  padding-left: calc(50% - var(--container-width) / 2);
  padding-right: calc(50% - var(--container-width) / 2);
  width: 100%;
}

// Container with margin
// ==================
%container-margin {
  margin-left: auto;
  margin-right: auto;
  width: var(--container-width);
}
