// Add Font Face
// ==================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @if length($weight) == 1 {
    @font-face {
      font-family: quote($name);
      font-style: $style;
      font-weight: $weight;
      src: $src;
    }
  } @else {
    $weights: $weight;
    @each $weight in $weights {
      $suffix: map-get((
              100: Thin,
              200: ExtraLight,
              300: Light,
              400: Regular,
              500: Medium,
              600: SemiBold,
              700: Bold,
              800: ExtraBold,
              900: Black
      ), $weight);
      @include font-face($name, $path + "-" + $suffix, $weight, $style, $exts)
    }
  }
}

// Responsive
// ==================
@mixin up($size) {
  @media (min-width: $size) { @content; }
}
@mixin down($size) {
  @media (max-width: $size) { @content; }
}
@mixin between($down, $up) {
  @media (min-width: $down) and (max-width: $up) { @content; }
}
