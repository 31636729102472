.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;

    .modal__content {
      transform: translateY(0);
    }
  }

  &.exit {
    opacity: 0;

    .modal__content {
      transform: translateY(-200px);
    }
  }
}

.modal__content {
  background-image: url('/assets/images/background.png');
  border-radius: var(--border-radius-6);
  color: var(--color-white);
  max-height: 95%;
  overflow-y: scroll;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
  width: 95%;

  iframe {
    height: 70vh;
    width: 100%;
  }
}

.modal__header,
.modal__footer {
  padding: space(2);
}

.modal__header {
  display: grid;
  grid-template-columns: 1fr auto;

  & > h4 {
    justify-self: center;
    margin: 0;
  }

  & > button {
    color: var(--color-white);
    width: fit-content;
  }
}

.modal__body {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: space(6) space(2);
}

.modal__footer {
  display: flex;
  justify-content: center;
}
