.progressbar {
  color: var(--color-cyan-blue);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: space(2);

  &::before {
    background-color: var(--color-cyan-blue);
    content: '';
    grid-column: 1;
    grid-row: 1;
    height: 1px;
    margin-top: 6px;
    width: 100%;
  }
}

.progressbar__steps {
  display: flex;
  grid-column: 1;
  grid-row: 1 / -1;
  justify-content: space-between;
}

.progressbar__step {
  color: var(--color-cyan-blue);
  display: grid;
  font-size: var(--font-size-12);
  grid-row-gap: space(2);
  grid-template-rows: repeat(2, auto);
  justify-items: center;
  text-transform: uppercase;

  &::before {
    background-color: var(--color-cyan-blue);
    border-radius: 50%;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
  }

  &--is-active {
    color: var(--color-white);

    &::before {
      background-color: var(--color-white);
    }
  }
}
