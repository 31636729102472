.step--search {
  display: grid;
  grid-row-gap: space(2);
  grid-template-columns: auto;
}

.step__position {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  line-height: var(--font-size-16);
}

.step__title {
  align-items: center;
  display: flex;
  color: var(--color-white);
  font-size: var(--font-size-27);
  line-height: var(--font-size-32);
  // max-width: 350px;

  & > img {
    margin-left: space(2);
  }

  & > button {
    margin-left: auto;
  }
}

.step__paragraph {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  line-height: var(--font-size-16);
  max-width: 350px;
}

.step__buttons {
  margin-top: space(2);
}
