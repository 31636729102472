.datepicker {
  display: flex;
  flex-direction: row;
  gap: space(4);
  height: 83px;
}

.datepicker__days,
.datepicker__months,
.datepicker__years {
  align-items: center;
  background-image: url('/assets/images/background-datepicker.png');
  color: var(--color-gray);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-12);
  height: 83px;
  line-height: var(--font-size-16);
  overflow-y: auto;

  & > * {
    padding: space(1);

    &:first-child {
      margin-top: space(5);
    }

    &:last-child {
      margin-bottom: space(5);
    }
  }

  & .is-selected {
    color: var(--color-white);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.datepicker__days {
  width: 85px;
}

.datepicker__months {
  width: 217px;
}

.datepicker__years {
  width: 85px;
}
