.quote-vehicle-manage__form {
  display: grid;
  grid-gap: space(4);
  grid-template-columns: repeat(3, 1fr);

  & > div:last-of-type {
    grid-column: 1 / span 3;
    justify-self: center;

    & > * + * {
      margin-left: space(4);
    }
  }

  .quote-vehicle-manage__form-input {
    position: relative;

    .input-list {
      background-color: var(--color-white);
      border-radius: var(--border-radius-6);
      color: var(--color-blue);
      display: none;
      left: space(1);
      max-height: 200px;
      overflow: scroll;
      position: absolute;
      right: space(1);
      z-index: 99;

      & > * {
        color: var(--color-blue);
        display: block;
        padding: space(1) space(1);
        text-align: left;
        width: 100%;
      }
    }
  }
}
