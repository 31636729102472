.card {
  align-items: center;
  background: linear-gradient(to top right, #3fbec7 30%, #2987b7 70%);
  border-radius: var(--border-radius-6);
  box-shadow: 0 0 10px 6px #2987b7;
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-14);
  justify-content: center;
  line-height: var(--font-size-16);
  overflow: auto;
  padding: space(4);
  width: 100%;

  &:not(.card--search) > div {
    font-size: var(--font-size-27);
    line-height: var(--font-size-32);
  }

  &--in-progress {
    background: linear-gradient(
      to top right,
      #3fbec7 30%,
      var(--color-green-light) 70%
    ) !important;
  }

  &--accepted {
    background: linear-gradient(
      to top right,
      #3fbec7 30%,
      var(--color-green-dark) 70%
    ) !important;
  }
}

.card--search {
  display: grid;
  grid-column-gap: space(2);
  grid-row-gap: space(6);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);

  & > div:nth-of-type(1) {
    font-size: var(--font-size-27);
    grid-column: 1;
    grid-row: 1;
    line-height: var(--font-size-32);
  }

  & > div:nth-of-type(2) {
    font-size: var(--font-size-27);
    grid-column: 2;
    grid-row: 1;
    line-height: var(--font-size-32);
    text-align: center;
  }

  & > div:nth-of-type(3) {
    grid-column: 1;
    grid-row: 2;
  }

  & > div:nth-of-type(4) {
    grid-column: 2;
    grid-row: 2;
    text-align: center;
  }

  & > div:nth-of-type(5) {
    font-size: var(--font-size-21);
    grid-column: 1 / span 2;
    grid-row: 3;
    text-align: center;
  }
}
