.search-card {
  background: linear-gradient(to top right, #3fbec7 30%, #2987b7 70%);
  border-radius: var(--border-radius-6);
  box-shadow: 0 0 10px 6px #2987b7;
  color: var(--color-white);
  display: grid;
  font-size: var(--font-size-14);
  grid-column-gap: space(2);
  grid-row-gap: space(6);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  line-height: var(--font-size-16);
  overflow: auto;
  margin-top: space(4);
  max-width: 100%;
  padding: space(3) space(4) space(4) space(4);
  width: fit-content;

  & > div:nth-of-type(1) {
    font-size: var(--font-size-27);
    grid-column: 1;
    grid-row: 1;
    line-height: var(--font-size-32);
  }

  & > div:nth-of-type(2) {
    font-size: var(--font-size-27);
    grid-column: 2;
    grid-row: 1;
    line-height: var(--font-size-32);
    text-align: center;
  }

  & > div:nth-of-type(3) {
    grid-column: 1;
    grid-row: 2;
  }

  & > div:nth-of-type(4) {
    grid-column: 2;
    grid-row: 2;
    text-align: center;
  }

  & > div:nth-of-type(5) {
    font-size: var(--font-size-21);
    grid-column: 1 / span 2;
    grid-row: 3;
    text-align: center;
  }

  & > div > div + div {
    margin-top: space(1);
  }
}
