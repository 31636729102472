.quote-repair-manage {
  color: var(--color-white);

  & > button {
    display: block;
    margin: space(4) auto 0 auto;
  }

  table {
    margin-top: space(4);
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid gray;

        th {
          font-size: var(--font-size-21);
          font-weight: var(--font-weight-bold);
          padding: 0 space(1) space(2) space(1);
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: space(0.5) space(1);
          position: relative;
          text-align: left;
        }

        &:nth-of-type(1) {
          td {
            padding-top: space(2);
          }
        }

        &:last-of-type {
          td {
            padding-bottom: space(2);
          }
        }
      }

      .quote-repair-manage__edit-with-unit {
        align-items: center;
        display: grid;
        grid-column-gap: space(1);
        grid-template-columns: 1fr auto;
      }

      input {
        max-width: 100%;
        padding: space(2) space(1);
      }

      .input-list {
        background-color: var(--color-white);
        border-radius: var(--border-radius-6);
        color: var(--color-blue);
        display: none;
        left: space(1);
        max-height: 200px;
        overflow: scroll;
        position: absolute;
        right: space(1);
        z-index: 99;

        & > * {
          color: var(--color-blue);
          display: block;
          padding: space(1) space(1);
          text-align: left;
          width: 100%;
        }
      }

      button.remove {
        background-color: red;
        border-color: red;
        border-radius: var(--border-radius-6);
        color: var(--color-white);
        min-width: 0px;
        padding: space(1) space(2);
      }

      button.add {
        border-radius: var(--border-radius-6);
        display: block;
        margin: space(2) auto 0 auto;
      }
    }

    tfoot {
      tr {
        border-top: 1px solid gray;

        td {
          font-size: var(--font-size-19);
          font-weight: var(--font-weight-bold);
          padding-top: space(2);
        }
      }
    }
  }
}
