.repair-order-list {
  display: flex;
  justify-content: center;

  & > div {
    & > h3 {
      font-size: var(--font-size-32);
      font-weight: var(--font-weight-bold);
    }

    & > p {
      margin-bottom: space(5);
    }

    & > div + div {
      margin-top: space(2);
    }
  }

  input[type='checkbox'] {
    transform: scale(1.5);
  }

  label {
    margin-left: space(2);
  }

  button {
    display: block;
    margin: space(6) auto 0 auto;
  }
}
