.quote-simulation-step {
  color: var(--color-white);
  display: grid;
  grid-gap: space(4);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto 1fr auto;
  height: 100%;
  padding-bottom: space(2);
  padding-top: space(10);
  width: 100%;
}

.quote-simulation-step__title {
  font-size: var(--font-size-27);
  grid-column: 1;
  grid-row: 1;
  line-height: var(--font-size-32);
}

.quote-simulation-step__resources {
  grid-column: 1;
  grid-row: 4;

  & > h3 {
    font-size: var(--font-size-27);
    line-height: var(--font-size-32);
    margin-bottom: space(4);
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: space(2);

    & > img {
      height: 100px;
      width: auto;
    }
  }
}

.quote-simulation-step__actions {
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: flex-end;
  row-gap: space(2);

  &:last-of-type {
    grid-row: 4;
  }

  & > button {
    align-self: flex-end;
    width: fit-content;
  }
}

.quote-simulation-step__customer-vehicle {
  display: grid;
  grid-column: 1;
  grid-column-gap: space(2);
  grid-row: 2;
  grid-template-columns: repeat(2, 1fr);

  & > div {
    border: 1px solid gray;
    border-radius: var(--border-radius-6);
    display: grid;
    padding: space(2);
    width: 100%;

    &:last-of-type {
      justify-self: end;
      text-align: end;

      & > div:nth-of-type(2) > span {
        font-size: var(--font-size-19);
        font-weight: var(--font-weight-medium);
        text-transform: uppercase;
      }
    }

    & > div:first-of-type {
      font-size: var(--font-size-27);
      margin-bottom: space(2);
      text-align: center;

      & > button {
        width: var(--font-size-27);
      }
    }

    & > button {
      margin-top: space(4);
    }
  }
}

.quote-simulation-step__repairs {
  align-items: center;
  grid-column: 1 / span 2;
  grid-row: 3;
  margin-top: space(4);

  & > div {
    align-items: center;
    column-gap: space(4);
    display: flex;
    font-size: var(--font-size-27);
    line-height: var(--font-size-32);
  }

  & > table {
    margin-top: space(4);
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid gray;

        th {
          font-size: var(--font-size-21);
          font-weight: var(--font-weight-bold);
          padding-bottom: space(2);
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: space(0.5) 0;
          text-align: left;
        }

        &:nth-of-type(1) {
          td {
            padding-top: space(2);
          }
        }

        &:last-of-type {
          td {
            padding-bottom: space(2);
          }
        }
      }
    }

    tfoot {
      tr {
        border-top: 1px solid gray;

        td {
          font-size: var(--font-size-19);
          font-weight: var(--font-weight-bold);
          padding-top: space(2);
        }
      }
    }
  }
}

.quote-simulation-step__edit {
  align-self: end;
  justify-self: center;
}

.quote-simulation-step__cgu {
  margin-bottom: space(1);
  margin-left: space(1);

  label {
    margin-left: space(3);

    span {
      color: var(--color-blue);
    }
  }

  input[type='checkbox'] {
    transform: scale(2);
  }
}

.quote-simulation-step__signature-wrapper {
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--color-blue);
  border-radius: var(--border-radius-6);
  height: 158px;
  width: 487px;

  &:focus {
    border-color: var(--color-blue);
  }
}
