.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: space(2);
  height: calc(100vh - (2 * var(--app-padding-x)));
}

.app__header {
  display: grid;
  grid-gap: space(6);
  grid-template-columns: auto 1fr;

  &--search {
    align-items: center;
    display: flex;
    column-gap: space(2);
    justify-self: end;

    & > div:first-of-type {
      align-items: center;
      color: var(--color-white);
      display: flex;
      flex-direction: column;
      margin-right: space(6);
      row-gap: space(1);

      input {
        transform: scale(1.5);
        width: fit-content;
      }
    }

    input {
      width: 300px;
    }
  }
}

.app__body {
  align-items: center;
  display: flex;
  justify-content: center;

  &--home {
    display: grid;
    grid-column-gap: space(8);
    grid-template-columns: repeat(3, 1fr);
  }
}
