.signature-step {
  display: grid;
  grid-gap: space(2);
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  padding-bottom: space(10);
  padding-top: space(10);
  width: 100%;
}

.signature-step__position {
  color: var(--color-gray);
  font-size: var(--font-size-12);
  grid-column: 1;
  grid-row: 1;
  line-height: var(--font-size-16);
}

.signature-step__note {
  grid-column: 1 / span 2;
  grid-row: 2;
}

.signature-step__signature {
  grid-column: 2;
  grid-row: 3;
  justify-self: end;
  text-align: end;

  .signature-step__signature-wrapper {
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid var(--color-blue);
    border-radius: var(--border-radius-6);
    height: 158px;
    width: 487px;

    &:focus {
      border-color: var(--color-blue);
    }
  }

  .signature-step__cgu {
    color: var(--color-white);
    margin-bottom: space(2);
    margin-left: space(1);
    text-align: left;

    label {
      margin-left: space(3);

      span {
        color: var(--color-blue);
      }
    }

    input[type='checkbox'] {
      transform: scale(2);
    }
  }

  & > button {
    margin-top: space(2);
  }
}

.signature-step__buttons {
  grid-column: 1 / span 2;
  grid-row: 4;
  justify-self: center;
  margin-top: space(4);

  & > button + button {
    margin-left: space(3);
  }
}

.signature-step__title {
  color: var(--color-white);
  font-size: var(--font-size-27);
  line-height: var(--font-size-32);
  margin-bottom: space(4);
}
