.quote-customer-manage__form {
  display: grid;
  grid-gap: space(4);
  grid-template-columns: repeat(3, 1fr);

  .input-error {
    color: red;
    margin-top: space(1);
  }

  & > div:last-of-type {
    grid-column: 1 / span 3;
    justify-self: center;

    & > * + * {
      margin-left: space(4);
    }
  }
}
