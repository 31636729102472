.search-step {
  width: 100%;
}

.search-step__form {
  display: grid;
  grid-column-gap: space(4);
  grid-template-columns: 400px 1fr;
}

.search-step__results {
  display: grid;
  grid-gap: space(4);
  grid-template-columns: repeat(2, 1fr);
  max-height: 510px;
  overflow-y: scroll;
  padding: space(2);
}
