.form-select {
  label {
    color: var(--color-white);
    display: block;
    font-size: var(--font-size-19);
    line-height: var(--font-size-21);
    margin-bottom: space(2);
  }

  select {
    background: rgba(64, 171, 202, 0.1);
    border: 1px solid var(--color-gray-blue);
    border-radius: var(--border-radius-6);
    color: var(--color-white);
    font-size: var(--font-size-14);
    line-height: var(--font-size-16);
    outline: none;
    padding: space(2) space(3);
    max-width: 400px;
    width: 100%;
    -webkit-appearance: none;

    & > option {
      background-color: #00054b;
    }

    &:focus {
      border-color: var(--color-blue);
    }
  }
}
