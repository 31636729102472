// str-replace
// ==================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Get space
// ==================
@function space($i) {
  @return calc(#{$i} * var(--space));
}

// Convert px to rem
// ==================
@function px2rem($px) {
  @return calc(#{$px}/16 * 1rem);
}
